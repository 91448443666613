@font-face {
	font-family: "Fira Code";
	src: local("Fira Code Bold"), local("FiraCode-Bold"),
		url("src/fira-code/FiraCode-Bold.woff2") format("woff2"),
		url("src/fira-code/FiraCode-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fira Code";
	src: local("Fira Code SemiBold"), local("FiraCode-SemiBold"),
		url("src/fira-code/FiraCode-SemiBold.woff2") format("woff2"),
		url("src/fira-code/FiraCode-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fira Code";
	src: local("Fira Code Medium"), local("FiraCode-Medium"),
		url("src/fira-code/FiraCode-Medium.woff2") format("woff2"),
		url("src/fira-code/FiraCode-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fira Code";
	src: local("Fira Code Light"), local("FiraCode-Light"),
		url("src/fira-code/FiraCode-Light.woff2") format("woff2"),
		url("src/fira-code/FiraCode-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Fira Code";
	src: local("Fira Code Regular"), local("FiraCode-Regular"),
		url("src/fira-code/FiraCode-Regular.woff2") format("woff2"),
		url("src/fira-code/FiraCode-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
